export const styleShowBtnSignUp = {
  opacity: "1",
  height: "48px",
  marginTop: "32px",
  transform: "scale(1)",
};

export const styleHideBtnSignUp = {
  opacity: "0",
  height: "0px",
  marginTop: "0px",
  transform: "scale(0)",
};
